export default function InfoTable({data}) {
    const styles={
        thead:{
            backgroundColor:"#DCC7FF",
            height:"38px"
        },
        th:{
            background: "#DCC7FF",
    color: "#000",
    fontWeight: 600,
    fontSize: "14px",
    padding: "20px",
    textAlign:"left"

        },
        label:{
            background: "#DCC7FF",
    color: "#000",
    fontWeight: 600,
    fontSize: "14px",
    padding: "20px",
    textAlign:"left",
    borderBottom:"1px solid #0000001f"

        },
        td:{
            background: "#FFF",
    color: "#000",
    fontSize: "14px",
    padding: "20px",
    borderBottom:"1px solid #0000001f"
        }
    }
    return (
      <table style={{width:"100%"}}>
          <tbody>
              {Object.keys(data).map((key, index) => {
                  return <tr key={index}>
                     
                          <td key={key} style={styles.label}>{key}</td><td style={styles.td}>{data[key]}</td>
                      
                </tr>;
              })}
          </tbody>
      </table>
   );
   }