import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
} from "@carbon/react";


import { Chat, Notification, UserAvatar } from "@carbon/icons-react";
import React from "react";
class AppHeader extends React.Component {
  render() {
    let styles = {
      header: {
        backgroundColor: "",
        height: "95px",
        position: "relative",
        zIndex: 0,
      },
    };
    return (
      <HeaderContainer
        style={styles.header}
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Header aria-label="Carbon" style={styles.header}>
            <SkipToContent />
            <HeaderMenuButton
              aria-label="Open menu"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
            <HeaderName href="/" prefix="">
              <Chat
                size={32}
                style={{ fill: "#000", paddingRight: "10px" }}
              ></Chat>{" "}
              Procurement Advisor
            </HeaderName>

            <HeaderGlobalBar style={{ height: "100%" }}>

            <img style={{ padding: "30px 5px 29px 14px",marginRight:"20px" }} alt="map" src={require("./entergylogo.png")} />
            
              
              <HeaderGlobalAction aria-label="Notifications">
                <Notification size={32} style={{ fill: "#000" }} />
              </HeaderGlobalAction>
              <HeaderGlobalAction aria-label="User Avatar">
                <UserAvatar size={32} style={{ fill: "#000" }} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
          </Header>
        )}
      />
    );
  }
}

export default AppHeader;
