import { defineCancelApiObject } from "./config/defineCancelApiObject"
import { api } from "./config/axiosConfigs"



export const chatAPI = {
    askBot: async function (query, cancel = false) {
        return api.request({
          url: `/reply`,
          method: "POST",
          data: query,
          signal: cancel ? cancelApiObject[this.getPaginated.name].handleRequestCancellation().signal : undefined,
        })
      }
}
const cancelApiObject = defineCancelApiObject(chatAPI)
