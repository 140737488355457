import React from "react";
import Table from "./Table";
import InfoTable from "./InfoTable";
import parse from 'html-react-parser';
import { messageDetailsService } from "../../utility/MessageDetailsService";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import { Button } from "@carbon/react";
import { PieChart, Pie, Sector,RadialBarChart, RadialBar, BarChart, Bar, Cell, XAxis, YAxis, Tooltip, Legend, Rectangle } from 'recharts';
import { scrollService } from "../../utility/ScrollService";
import { Download } from "@carbon/icons-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  // 'pdfjs-dist/build/pdf.worker.min.js',
  // import.meta.url,
// ).toString();



class ChatDetails extends React.Component {
  constructor(props) {
    super(props);
    this.itemEls = React.createRef(new Array())

    this.state = {
      contents: [],
      message: "",
      numPages:null,
      pageNumber:1
    };
    this.messagesEndRef = React.createRef();
  }
 contentImage = '';

 onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({numPages:numPages});
	};
 goToPrevPage = () =>
 this.setState({pageNumber:(this.state.pageNumber - 1 <= 1 ? 1 : this.state.pageNumber - 1)});

goToNextPage = () =>
this.setState({pageNumber:this.state.pageNumber + 1 >= this.state.numPages ? this.state.numPages : this.state.pageNumber + 1});
  componentDidMount() {
    this.subscription = messageDetailsService.getDetailMessage().subscribe((message) => {
      this.messagesEndRef = React.createRef();
      if (message) {
        this.setState({
          contents: [...this.state.contents, message.value],
        })
       /* // add message to local state if not empty
        let chatContent = chatMessage[message.value];
        this.setState({ message: message.value });
        if (!chatContent) {
          chatContent = {
            type: "Empty",
            message: "Sorry no answer found for your query",
            data: "",
          };
        }
        setTimeout(() => { this.setState({
          contents: [...this.state.contents, chatContent],
        });},3000)*/
       
      } else {
        // clear messages when empty message received
        this.setState({ contents: [] });
      }
      this.scrollToBottom();
    });
    this.scrollsubscrtion = scrollService.getDetailScroll().subscribe((id)=>{
      this.scrollToElement("chtdt-"+id.value);
    })

  }
  componentDidUpdate() {
    this.scrollToBottom();
  }
  scrollToBottom = () => {
    this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  scrollToElement(id){
    document.getElementById(id).scrollIntoView({behavior:"smooth"})
  }
  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  renderContent(content) {
    const styles = {
      content: {
        background: "",
        border: "1px solid #666666",
        padding: "20px",
        marginTop: "10px",
        borderRadius: "",
      },
      title: { fontWeight: 600, marginBottom: "20px" },
      textarea: {
        backgroundColor: "white",
        padding: "20px",
        whiteSpace: "pre"
      }
    };
    this.contentImage = content.data;
    console.log(this.contentImage)
    switch (content.type) {
      case "table":
        return (
          <div id={"chtdt-"+content.key}style={{ width: "95%" }}>
            <div style={styles.content}>
              <p style={styles.title}>{content.message}</p>
              {content.data.length > 0 &&

              <Table
                theadData={Object.keys(content.data[0])}
                tbodyData={content.data}
              ></Table>
        }
            </div>
          </div>
        );
      case "map":
        return (
          <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
            <div style={styles.content}>
              <p style={styles.title}>{content.message}</p>
              <img style={{ width: "100%" }} alt="map" src={require("./map.png")} />
              {content.tabledata.length > 0 &&
              <Table
                theadData={Object.keys(content.tabledata[0])}
                tbodyData={content.tabledata}
              ></Table>
    }
            </div>
          </div>
        );
        case "image":
        return (
          <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
            <div style={styles.content}>
              <p style={styles.title}>{content.reply}</p>
              <img style={{ width: "100%" }} alt="view" src={require("./markupcharege.png")} />
            </div>
          </div>
        );
        case "image1":
          return (
            <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
              <div style={styles.content}>
                <p style={styles.title}>{content.reply}</p>
                <img style={{ width: "100%" }} alt="view" src={require("./historicalrfx.png")} />
              </div>
            </div>
          );
          case "imageCommon":
          return (
            <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
              <div style={styles.content}>
                <p style={styles.title}>{content.reply}</p>
                <img style={{ width: "100%" }} alt="view" src={this.contentImage} />
              </div>
            </div>
            
          );
          case "imageCommon1":
          return (
            <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
              <div style={styles.content}>
                <p style={styles.title}>{content.reply}</p>
                <img style={{ width: "100%" }} alt="view" src={content.data} />
                <img style={{ width: "100%" }} alt="view1" src={content.data1} />
              </div>
            </div>
            
          );
          
      case "text1":
        return (
          <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
          </div>
        );
        
      case "json":
        return (
          <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
            <div style={styles.content}>
              <p style={styles.title}>{content.message}</p>
            <InfoTable data={content.data}></InfoTable>
          </div>
          </div>
        );
      case "graph":
        const style = {
          top: '50%',
          right: 0,
          transform: 'translate(0, -50%)',
          lineHeight: '24px',
        };
        if(content.graph_type==="radial"){
        return (
          <div  id={"chtdt-"+content.key} style={{ width: "95%" }}>
            <div style={styles.content}>
              <p style={styles.title}>{content.message}</p>
        <RadialBarChart cx="50%" cy="50%"             width={700} height={500}        innerRadius="30%" outerRadius="80%" barSize={20} data={content.data}>
          <RadialBar
            minAngle={15}
            label={{ position: 'insideStart', fill: '#fff' }}
            background
            clockWise
            dataKey="uv"
          />
          <Tooltip />
          <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} />
        </RadialBarChart></div></div>)
        }
       if(content.graph_type === "bar"){
        return (
          <div  id={"chtdt-"+content.key} style={{ width: "95%" }}>
            <div style={styles.content}>
              <p style={styles.title}>{content.message}</p>
          <BarChart width={700} height={200} data={content.data}>
            <XAxis dataKey={content.x_axis} />
          <YAxis dataKey={content.y_axis}/>
          <Tooltip />
          <Legend />
          <Bar dataKey={content.y_axis} fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />

          </BarChart>
        </div></div>
        
        )
       }
       if(content.graph_type === "pie"){
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

        return (
          <div  id={"chtdt-"+content.key} style={{ width: "95%" }}>
          <div style={styles.content}>
            <p style={styles.title}>{content.message}</p>    
          <PieChart width={800} height={400} onMouseEnter={this.onPieEnter}>
        <Pie
          data={content.data}
          cx={220}
          cy={200}
          innerRadius={100}
          outerRadius={150}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {content.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
          <Legend />
            </PieChart>
            </div></div>
        )
       }
      case "": 
          if(content.doclink)
          return (
            <div id={"chtdt-"+content.key} style={{ width: "95%" }}>
            <div style={styles.content}>
              <p style={styles.title}>{content.message}</p>  
              <nav style={{textAlign:"center"}}>
				<button onClick={this.goToPrevPage}>Prev</button>
				<button onClick={this.goToNextPage}>Next</button>
				<p>
					Page {this.state.pageNumber} of {this.state.numPages}
				</p>
			</nav>
           <Document file={content.doclink} onLoadSuccess={this.onDocumentLoadSuccess}>
                      <Page pageNumber={this.state.pageNumber}   renderTextLayer={false}
        renderAnnotationLayer={false}
        customTextRenderer={false}  width={900} scale={1}/>

            </Document>
            <nav style={{textAlign:"center"}}>
				<button onClick={this.goToPrevPage}>Prev</button>
				<button onClick={this.goToNextPage}>Next</button>
				<p>
					Page {this.state.pageNumber} of {this.state.numPages}
				</p>
			</nav>
            </div></div>
          /*  <iframe  src={content.doclink} height={"500px"} width={"500px"}></iframe>*/
            
        );

      default: return ("")
    }
  }
  render() {
    const { contents } = this.state;
    const styles = {
      detail: {
        display: "flex",
        position: "relative",
        height: "80vh",
        overflowY: "auto",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      },
      label: {
        borderRadius: "5px",
        backgroundColor: "#fff",
        width: "50%",
        padding: "12px",
        border: "1px solid rgba(0, 0, 0, 0.15)",
        height: "44px",
        marginTop: "20px",
        fontStyle: "italic",
        color: "#9A9C9E",
      },
    };
    return (
      <div style={styles.detail}>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div style={styles.label}>{this.state.message}</div>
          <div style={{ padding: "18px", display: "flex",height: "1px",width:"1px" }}>
           
              {/* <div
                style={{
                  7777777777: "16px",
                  color: "#1f51c4",
                  fontWeight: "600",
                }}
              >
                LIVE Weather
              </div>
              <img src={weatherImg} alt=""/>
              <span style={{ fontSize: "26px", color: "#1f51c4" }}>
                26&deg;
              </span> */}
                <Button variant="secondary" >Download<Download size={32} style={{ fill: "#ffffff" }} /> </Button>
           
          </div>
          {/* <div
            style={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              fontWeight: "500",
            }}
          >
            <div>&nbsp;</div>
            <div style={{ fontSize: "12px" }}>Beaumont, TX</div>
            <div style={{ fontSize: "10px" }}>Thursday, 9:00am</div>
            <div style={{ fontSize: "10px" }}>Sunny</div>
          </div> */}
        </div>
        {contents.map((content, index) => this.renderContent(content))}
        <div ref={this.messagesEndRef} />
      </div>
    );
    /* if(this.state.content.type)
        {
            return(<Table theadData={Object.keys(this.state.content.data[0])} tbodyData={this.state.content.data}></Table>)

        } else
        {
            return (<div></div>)
        }*/
  }
}
export default ChatDetails;
