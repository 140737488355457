import "./chatinput.css";
import { Send } from "@carbon/icons-react";
import React from "react";
import { TextInput } from "@carbon/react";
import { messageService } from "../../utility/MessageService";
class ChatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
  }

  send() {
    messageService.setMessage(this.state.text);
    this.setState({ text: "" });
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.send();
    }
  };
  render() {
    return (
      <div id="chat-view">
        <TextInput
          id="chat-input"
          type="text"
          onKeyPress={this.handleKeyPress}
          onChange={(newText) => this.setState({ text: newText.target.value })}
          value={this.state.text}
          hideLabel={true}
          labelText="Start typing here"
          placeholder="Start typing here"
        />
        <button
          id="send-button"
          iconDescription="Send"
          onClick={() => this.send()}
        >
          <Send size={24}></Send>
        </button>
      </div>
    );
  }
}

export default ChatInput;
