import { Button, Link } from "@carbon/react";
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';


export default function Table({theadData, tbodyData}) {
    let tData=tbodyData;
    let tHead =theadData;
    let showModal= false;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const styles={
        thead:{
            backgroundColor:"#DCC7FF",
            height:"38px"
        },
        th:{
            background: "#DCC7FF",
    color: "#000",
    fontWeight: 600,
    fontSize: "14px",
    padding: "20px",
    textAlign:"left"

        },
        td:{
            background: "#FFF",
    color: "#000",
    fontSize: "14px",
    padding: "20px",
    borderBottom:"1px solid #0000001f"
        }
    }
    if(theadData.length> 10){
      tHead = theadData.slice(0,10)
      showModal= true;
    }
    if(tbodyData.length > 10){
        tData=tbodyData.slice(0,10);
        showModal= true;
    }
    console.log(tHead,tData)
    return (
		<div><div style={{overflow: "auto", whiteSpace: "nowrap"}}>
      <table style={{width:"100%"}}>
          <thead style={styles.thead}>
             <tr>
              {tHead.map(heading => {
                return <th key={heading} style={styles.th}>{heading}</th>
              })}
            </tr>
          </thead>
          <tbody>
              {tData.map((row, index) => {
                console.log(row,index)
                  return <tr key={index}>
                      {tHead.map((key, index) => {
                           return <td key={row[key]} style={styles.td}>{row[key]}</td>
                      })}
                </tr>;
              })}
          </tbody>
      </table>
      <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      {showModal &&
    <Modal show={show} size="lg" onHide={handleClose} scrollabl={true}>
        <Modal.Body style={{overflow: "auto", whiteSpace: "nowrap"}}>
        <table style={{width:"100%"}}>
          <thead style={styles.thead}>
             <tr>
              {theadData.map(heading => {
                return <th key={heading} style={styles.th}>{heading}</th>
              })}
            </tr>
          </thead>
          <tbody>
              {tbodyData.map((row, index) => {
                  return <tr key={index}>
                      {theadData.map((key, index) => {
                           return <td key={row[key]} style={styles.td}>{row[key]}</td>
                      })}
                </tr>;
              })}
          </tbody>
      </table>
        </Modal.Body>
      
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
}
    </div>
      </div>
	  {showModal &&
		<div style={{textAlign:"right"}}> <Link onClick={handleShow}>More detail ...</Link></div>
		}
	</div>
   );
   }