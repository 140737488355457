import React from "react";
import Messages from "./Messages";
import { Content } from "@carbon/react";
import { messageService } from "../../utility/MessageService";


class Conversation extends React.Component{
    messages=[]
    constructor(props) {
        super(props)
        messageService.getMessage().subscribe(message => {
        this.messages.push({from:"USER",message:message})
    })
} 
render(){
    const style={
        content:{
            width:"100%",
            overflow: "auto",
            height:"80vh",
            padding:"0px",
            position:"relative"
        }
    }
    return <Content style={style.content}> 
        <Messages></Messages>
    </Content>
}

}

export default Conversation