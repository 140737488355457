import React from "react";
import { DocumentPdf, Db2Database,Chat} from "@carbon/icons-react";
import chatMessage from "./data.json";
import assitantAvatar from "./assistant.svg"
import { messageService } from "../../utility/MessageService";
import LoadingIcons from 'react-loading-icons'
import { chatAPI } from "../../api/chatAPI";
import { messageDetailsService } from "../../utility/MessageDetailsService";
import parse from 'html-react-parser';
import { scrollService } from "../../utility/ScrollService";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

class Messages extends React.Component {
  count=0;


  constructor(props) {
    
    super(props);
    window.sendDetail = this.sendDetail;
    this.messagesEndRef = React.createRef();
    localStorage.clear()
    

    this.state = {
      messages: [],
      loading:false
    };
  }
  styles = {
    suggestion: {
      color: "#6929C4",
      fontFamily: "IBM Plex Sans",
      fontSize: "14px",
      fontStyle: "italic",
      fontWeight: " 450",
      lineHeight: "1.5",
      letterSpacing: " 0px",
      textAlign: "left",
      marginTop: "10px",
    },
    message: {
      display: "flex",
      padding: "10px",
      flexFlow: "row wrap",
      width: "100%",
    },
    messageStyle: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
      flexBasis: "100%",
      marginTop: "20px",
      alignItems: "flex-end",
    },
    messagebody: {
      width: "60%",
      background: "linear-gradient(99.74deg, #ffccda -37.11%, #e1ccff 150.44%)",
      borderRadius: "5px",
      borderTopRightRadius: "0px",
      padding: "10px",
      lineHeight: 1.5,
    },
    answerStyle: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
      flexBasis: "100%",
      marginTop: "20px",
      justifyContent: "left",
    },
    answerbody: {
      width: "60%",
      backgroundColor: "#dcc7ff",
      background: "linear-gradient(99.74deg, #FFFFFF -37.11%, #D2D2D2 100.44%)",
      borderRadius: "5px",
      borderTopLeftRadius: "0px",
      padding: "10px",
      lineHeight: 1.5,
    },
    subtext: {
      color: "#AEAEAE",
      fontSize: "12px",
      textAlign: "left",
      padding: "10px 0px",
    },
    paragraph: {
      fontSize: "14px",
      fontWeight: "400",
      marginTop: "10px",
    },
    notice: {
      fontSize: "14px",
      fontWeight: "400",
      marginTop: "10px",
      fontStyle: "italic",
    },
  };

  sendDetail(msg){
    console.log(chatMessage, msg,"hello")
    var resp=chatMessage[msg];
    if(resp) {resp.key=this.count;
    
    messageDetailsService.setDetailMessage(resp);}
  }

  componentDidMount() {
    // subscribe to home component messages
    this.subscription = messageService.getMessage().subscribe((message) => {
      if (message) {
        // add message to local state if not empty
        if (message) {
          this.count++;
          this.setState({ messages: [...this.state.messages, {type:"query",key:this.count,value:message.value}],loading:true });
    
          if(chatMessage[message.value]){
            let msg={"query":message.value,response:chatMessage[message.value]}
            localStorage.setItem(this.count,JSON.stringify(msg))
            setTimeout(() => { 
              if(chatMessage[message.value]){
              this.setState({ messages: [...this.state.messages, {type:"reply",key:this.count,value:chatMessage[message.value].message,context:chatMessage[message.value].type,doclink:chatMessage[message.value].doclink}],loading:false });
              var resp=chatMessage[message.value];
              resp.key=this.count;
              messageDetailsService.setDetailMessage(resp);
              this.scrollToBottom()

              if(resp.chain){
                setTimeout(()=>{
                  this.count++;
                  this.setState({messages:[...this.state.messages,{type:"reply",key:this.count,value:resp.chain,context:resp.type,doclink:resp.doclink}],loading:false})
                  this.scrollToBottom();

                  if(resp.chain_img)
                  { console.log("Inside Here")
                     let respChain ={}
                     respChain.data = resp.chain_img;
                     respChain.type = resp.type;
                     respChain.key=this.count;
                    messageDetailsService.setDetailMessage(respChain)
                  }
                  this.scrollToBottom()
                 // 
                },2000)
              }
             
              
              } else {
                let response= {type:"reply",key:this.count,value:{message:"Sorry, I don't have answer for that!!"}}
                let msg={"query":message.value,response:response}

                msg.response.query = message.
                localStorage.setItem(this.count,msg)
                this.setState({ messages: [...this.state.messages, {type:"reply",key:this.count,value:{message:"Sorry, I don't have answer for that!!"}}],loading:false });
  
              }
              this.scrollToBottom()
            },2000)

          } else
         chatAPI.askBot({question:message.value}).then((response) => {
            if(response.data.body.response.type === "")
            {
              response.data.body.response.key=this.count
              localStorage.setItem(this.count,JSON.stringify(response.data.body))
              let data = response.data.body.response.data.replaceAll("\n", "<br />");

              this.setState({ messages: [...this.state.messages, {type:"reply",key:this.count,value:data,context:"",doclink:response.data.body.response.doclink}],loading:false });
              if(response.data.body.response.doclink){
                messageDetailsService.setDetailMessage(response.data.body.response)
              }
            } else {
              console.log("hello hello")
              response.data.body.response.key=this.count
              localStorage.setItem(this.count,JSON.stringify(response.data.body))
              messageDetailsService.setDetailMessage(response.data.body.response)
              this.setState({ messages: [...this.state.messages, {type:"reply",key:this.count,value:"Check details on right panel",context:response.data.body.response.type,doclink:response.data.body.response.doclink}],loading:false });
            }

        })
        .catch( (error) => {
          this.setState({ messages: [...this.state.messages, {type:"reply",value:"Sorry, facing some technical difficulties. Try another question."}],loading:false });
        }

        )
        .finally(() => {
          console.log("Final")
        })
         /* setTimeout(() => { 
            if(chatMessage[message.value]){
            this.setState({ messages: [...this.state.messages, {type:"reply",value:chatMessage[message.value]}],loading:false });
            } else {
              this.setState({ messages: [...this.state.messages, {type:"reply",value:{message:"Sorry, I don't have answer for that!!"}}],loading:false });

            }
          },2000)*/
        }
      } else {
        // clear messages when empty message received
        this.setState({ messages: [],replies:[]});
      }

    });
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }
  scrollToBottom = () => {
    this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  scrollToDetail(msgkey) {
    const type=['map','text','graph','table',"json","imageCommon","image","image1","text1"]
    let msg = JSON.parse(localStorage.getItem(msgkey))

    if(msg){
    if(type.includes(msg.response.type))
    {
    scrollService.setDetailScroll(msgkey)
    } else if(msg.response.doclink){
      scrollService.setDetailScroll(msgkey)
    } else {
      alert("Nothing on detail panel for this chat query, please check the response below.")
    }
  }  else {
    alert("Nothing on detail panel for this chat query, please check the response below.")
  }
  }
  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  render() {
    const { messages } = this.state;
    const options = { month: "long", day: "numeric", year: "numeric" };

    return (
      <div>
          <div style={{borderBottom: "1px solid rgba(0, 0, 0, 0.15)",fontSize:"16px",padding: "20px", width: "100%"}}>
            Your Digital Assistant 
          </div>
          <div style={{display:"flex",padding:"10px"}}>
            <div style={{marginRight:"10px"}}>
              <img src={assitantAvatar} alt="assistant"></img>
            </div>
            <div>
            <p style={this.styles.paragraph}>
              {" "}
              Hi John, I'm your digital assistant, I'm committed to doing my best to provide you with the information you need and assist you in completing your tasks more efficiently.
            </p>

            <p style={this.styles.notice}>
            Smooth operations ahead! Clear skies and sunshine today, with temperatures around comfortable 77 degrees.
             Light breeze from the west at 10km/h. Enjoy the pleasant weather!
            </p>
            <p style={this.styles.paragraph}>You can try:</p>
            <div style={this.styles.suggestion}>
              <div>Give me the spend report for last quarter?</div>
              <div>Show me the status of the saving initiatives pipeline?</div>
              <div>What new savings opportunities have been identified?</div>

            </div>
            </div>
          </div>
    
        {messages.map((message, index) => (
          <div style={this.styles.message}>
            { message.type === "query"  &&
            <div key={"q"+index} style={this.styles.messageStyle}>
              <span style={this.styles.subtext}>
                John Doe {new Date().toLocaleDateString(undefined, options)}
              </span>
              <div style={this.styles.messagebody} onClick={()=>this.scrollToDetail(message.key)}>{parse(message.value)}</div>
            </div>
            }
          { message.type === "reply"  &&

            <div key={"a" + index} style={this.styles.answerStyle}>
              <div style={{display:"flex"}}>
            <div style={{marginRight:"10px"}}>
              <img src={assitantAvatar} alt="assistant"></img>
            </div><div style={{display:"flex",flexDirection:"column",width:"100%"}}>
              <span style={this.styles.subtext}>
               {message.context === "table" && 
               <Db2Database></Db2Database>} 
               {message.context === "" && 
               <Chat />} Assistant {new Date().toLocaleDateString(undefined, options)}                   </span>
           
              <span style={this.styles.answerbody}>
              {parse(message.value,{
        replace: domNode => {
          if (domNode.name === 'a') {
            console.log(domNode)
          	delete domNode.attribs.onclick;
            return (
              <a
                {...domNode.attribs}
                onClick={() => {
    var resp=chatMessage[domNode.children[0].data.trim()];
    console.log(chatMessage, domNode.children[0].data,resp,"hello")

    if(resp) {resp.key=this.count;
      this.count++;
      this.setState({ messages: [...this.state.messages, {type:"query",key:this.count,value:domNode.children[0].data.trim()}],loading:true });
      messageService.setMessage(domNode.children[0].data.trim());
   // messageDetailsService.setDetailMessage(resp);
  }
                }}
              >{domNode.children[0].data}</a>
            );
          }
        }
      })} 
              </span>
             </div></div>
            </div>
  }
           
                
          </div>
        ))}
         <div ref={this.messagesEndRef} />
                {
                  this.state.loading && 
                  <div key={"reference"} style={this.styles.answerStyle}>
                  <div style={{display:"flex"}}>
                <div style={{marginRight:"10px"}}>
                  <img src={assitantAvatar} alt="assistant"></img>
                </div><div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                  <span style={this.styles.subtext}>
                    Assistant {new Date().toLocaleDateString(undefined, options)}
                  </span>
                  <span style={this.styles.answerbody}>
                    <div> <LoadingIcons.ThreeDots fill={"#000"} height={"10px"} width={"20px"} /></div>
                    </span></div></div>
            </div>
  }
      </div>
    );
  }
}
export default Messages;
