import { Content } from "@carbon/react";
import ChatInput from "./ChatInput";

function ChatBox() {
  return (
    <Content
      style={{ borderTop: "1px solid rgba(0, 0, 0, 0.15)", padding: "1rem" }}
    >
      <ChatInput />
    </Content>
  );
}
export default ChatBox;
