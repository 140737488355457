import React from "react";
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import "./AppSideNav.css"

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import {
    Chat,Notification,Application,Favorite
  } from '@carbon/icons-react';
const styles={
    nav:{
        backgroundColor:"#9EF0F0",
        // backgroundImage : linear-gradient(rgba(0, 224, 255, 1), rgba(0, 133, 255, 1))
        background: 'linear-gradient(to bottom, #FF1A58, #8026FF)'
    },
    nav_link:{color:"black"},

    toggle:{
    color:"#000",
    
    }
}
class AppSideNav extends React.Component{
    render(){
        return (
            <SideNav style={styles.nav}
    onSelect={(selected) => {
        // Add your code here
    }}
>
    <SideNav.Toggle   />
    <SideNav.Nav defaultSelected="assistant">
        <NavItem eventKey="assistant">
            <NavIcon>
                <Chat size={20} style={{fill:"#000"}}></Chat>
            </NavIcon>
            <NavText style={styles.nav_link}>
                Assistant
            </NavText>
        </NavItem>
        <NavItem eventKey="notification">
            <NavIcon>
                <Notification size={20} style={{fill:"#000"}}></Notification>
            </NavIcon>
            <NavText style={styles.nav_link}>
                Notification
            </NavText>
        </NavItem> 
        <NavItem eventKey="keymetrics">
            <NavIcon>
                <Application size={20} style={{fill:"#000"}}></Application>
            </NavIcon>
            <NavText style={styles.nav_link}>
                Keymetrics
            </NavText>
        </NavItem> 
        <NavItem eventKey="favourites">
            <NavIcon>
            <Favorite size={20} style={{fill:"#000"}}/>
            </NavIcon>
            <NavText style={styles.nav_link}>
                Favourites
            </NavText>
        </NavItem> 
   </SideNav.Nav>
</SideNav>
            
        )
    }
}

export default AppSideNav;