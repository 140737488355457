import "./App.scss";
import AppHeader from "./components/header/AppHeader";
import "react-chatbot-kit/build/main.css";
import ChatDetails from "./components/chat/ChatDetails";
import ChatBox from "./components/chat/ChatBox";
import Conversation from "./components/chat/Conversation";
import AppSideNav from "./components/sidenav/AppSideNav";

function App() {
  return (
    <div>
      <AppSideNav></AppSideNav>
      <div
        style={{
          height: "100%",
          position: "relative",
          paddingLeft: "65px",
          width: "100%",
          background: " #FFFFFF"
          ,
        }}
      >
        <AppHeader></AppHeader>
        <div style={{ display: "flex" }}>
          <div
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 0.15)",
              width: "40%",
            }}
          >
            <Conversation />
            <ChatBox />
          </div>
          <div style={{ width: "60%" }}>
            <ChatDetails />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
